import axiosInstance from '@/services/axiosInstance'

async function getFilter1 (groupId, categoryId, siteId, startDate, endDate, data) {
  const req = {
    url: `filter/filtro1/${groupId}/${categoryId}/${siteId}/${startDate}/${endDate}`,
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)

  return res.data
}

async function getFilter2 (groupId, categoryId, siteId, startDate, endDate, data) {
  const req = {
    url: `filter/filtro2/${groupId}/${categoryId}/${siteId}/${startDate}/${endDate}`,
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)

  return res.data
}

async function getFilter3 (groupId, categoryId, siteId, startDate, endDate, data) {
  const req = {
    url: `filter/filtro3/${groupId}/${categoryId}/${siteId}/${startDate}/${endDate}`,
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)

  return res.data
}

async function getFilter4 (groupId, categoryId, siteId, startDate, endDate, data) {
  const req = {
    url: `filter/filtro4/${groupId}/${categoryId}/${siteId}/${startDate}/${endDate}`,
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)

  return res.data
}

async function getFilter5 (groupId, categoryId, siteId, startDate, endDate, data) {
  const req = {
    url: `filter/filtro5/${groupId}/${categoryId}/${siteId}/${startDate}/${endDate}`,
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)

  return res.data
}

export { getFilter1, getFilter2, getFilter3, getFilter4, getFilter5 }
