<template>
<div class="flex flex-grow w-full md:w-11/12 md:mx-auto 2xl:w-full 2xl:pl-10 px-4 overflow-hidden">
  <div class="flex flex-col w-full h-full">
    <div class="flex flex-wrap p-2">
      <legend-label
        v-for="(category, index) in categories"
        :key="index"
        :category="category"
        :color="colors[index]"
        customClass="mr-2 mb-2"
        @toggle="toggleSeries"
        @highlight="highlightSeries"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
      />
    </div>
    <div class="flex w-full h-full pb-6">
      <template v-if="load">
        <highcharts v-if="treemapChart" :ref="treemapChart.ref" class="w-full" :options="treemapChart.options"></highcharts>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import LegendLabel from '@/components/LegendLabel'
import csvMixin from '@/mixins/csvMixin'
import { deepClone } from '@/utils/general'

export default {
  name: 'AppFeatureViewBHighcharts',
  components: {
    LegendLabel
  },
  mixins: [csvMixin],
  props: {
    data: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    disableLegend: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: 'table'
    }
  },
  data: function () {
    return {
      hoverColors: this.colors,
      localData: deepClone(this.data),
      legendHover: null,
      legendDisable: [],
      checkedLegend: [],
      load: false
    }
  },
  computed: {
    references: function () {
      return this.localData.map(item => item.ref)
    },
    treemapChart: function () {
      return this.localData[0]
    },
    treemapChartColors: function () {
      let colors = []
      if (this.treemapChart) {
        const disabledPositions = []
        const legendDisable = this.legendDisable
        for (let index = 0; index < this.treemapChart.options.series.length; index++) {
          const serie = this.treemapChart.options.series[index]
          if (legendDisable.includes(serie.name)) {
            disabledPositions.push(index)
          }
        }
        colors = this.treemapChart.options.colors.filter((color, index) => !disabledPositions.includes(index))
      }

      return colors
    },
    treemapChartSeries: function () {
      let series = []
      if (this.treemapChart) {
        const legendDisable = this.legendDisable
        series = this.treemapChart.options.series.filter(serie => !legendDisable.includes(serie.name))
      }
      return series
    },
    treemapChartOptions: function () {
      let options = {}
      if (this.treemapChart) {
        options = { ...this.treemapChart.options }
        options.series = [...this.treemapChartSeries]
        options.colors = [...this.treemapChartColors]
      }

      return options
    },
    activeCategories: function () {
      const legendDisable = this.legendDisable
      const categories = this.categories.filter(category => !legendDisable.includes(category))
      return categories
    }
  },
  methods: {
    toggleSeries: function (name) {
      if (this.legendDisable.includes(name)) {
        this.legendDisable = this.legendDisable.filter(item => item !== name)
      } else {
        this.legendDisable.push(name)
      }
    },
    isBrandInSerie: function (brandName, serieRef) {
      const object = this.localData.find(item => item.ref === serieRef)

      if (object) {
        const serie = object.series.find(serie => serie.name === brandName)

        return !!serie
      }

      return false
    },
    addInactiveSeriesAll: function () {
      this.setStateSeriesAll('inactive')
    },
    removeInactiveSeriesAll: function () {
      this.setStateSeriesAll()
    },
    setStateSeriesAll: function (state = '') {
      for (const ref of this.references) {
        const chart = this.$refs[ref].chart
        const points = chart.series[0].points

        for (const point of points) {
          const color = point.color
          if (state === 'inactive') {
            const index = this.colors.findIndex(c => c === color)
            if (index >= 0) {
              point.update({
                color: this.hoverColors[index]
              })
            }
          } {
            const index = this.hoverColors.findIndex(c => c === color)
            if (index >= 0) {
              point.update({
                color: this.colors[index]
              })
            }
          }
        }
      }
    },
    removeInactiveSerie: function (name) {
      for (const ref of this.references) {
        const chart = this.$refs[ref].chart
        const points = chart.series[0].points.filter(point => point.id === name || point.parent === name)

        for (const point of points) {
          const color = point.color
          const index = this.hoverColors.findIndex(c => c === color)
          if (index >= 0) {
            point.update({
              color: this.colors[index]
            })
          }
        }
      }
    },
    setCheckedLegend: function () {
      if (this.checkedLegend.length) {
        this.addInactiveSeriesAll()
        for (const value of this.checkedLegend) {
          this.removeInactiveSerie(value)
        }
      } else {
        this.removeInactiveSeriesAll()
      }
    },
    highlightSeries: function (value) {
      if (this.checkedLegend.includes(value)) {
        this.checkedLegend = this.checkedLegend.filter(item => item !== value)
      } else {
        this.checkedLegend.push(value)
      }
    },
    onMouseover: function (name) {
      this.legendHover = name
    },
    onMouseleave: function () {
      this.legendHover = null
    },
    loadChart: function () {
      this.load = false
      setTimeout(() => {
        this.load = true
      }, 100)
    }
  },
  created: function () {
    this.hoverColors = this.hoverColors.map(color => color.replace('rgb', 'rgba').replace(')', ', 0.2)'))
    this.loadChart()
  },
  watch: {
    legendHover: function (value) {
      if (value !== null) {
        this.addInactiveSeriesAll()
        this.removeInactiveSerie(value)

        for (const item of this.checkedLegend) {
          this.removeInactiveSerie(item)
        }
      } else {
        this.setCheckedLegend()

        for (const item of this.checkedLegend) {
          this.removeInactiveSerie(item)
        }
      }
    },
    checkedLegend: function () {
      this.setCheckedLegend()
    },
    legendDisable: function () {
      const options = deepClone(this.data[0].options)
      const legendDisable = this.legendDisable
      options.series[0].data = options.series[0].data.filter(item => !(legendDisable.includes(item.id) || legendDisable.includes(item.parent)))

      const ref = this.treemapChart.ref
      this.$refs[ref].chart.update(options)
    },
    data: function () {
      this.localData = deepClone(this.data)
      this.loadChart()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  .chart {
    flex-grow: 1;
  }
  .legend {
    width: 300px;
    padding: 20px;
  }
}
.legend {
  .legend-item {
    display: flex;
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    .color {
      display: block;
      width: 1em;
      height: 1em;
      margin-right: .5em;
    }
    input {
      display: none;
    }
    &[data-disabled] {
      background: grey;
    }
  }
}
</style>
