export default {
  methods: {
    componentFromStr: function (numStr, percent) {
      var num = Math.max(0, parseInt(numStr, 10))
      return percent
        ? Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num)
    },
    rgbToHex: function (rgb) {
      var rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/
      var result; var r; var g; var b; var hex = ''
      if ((result = rgbRegex.exec(rgb))) {
        r = this.componentFromStr(result[1], result[2])
        g = this.componentFromStr(result[3], result[4])
        b = this.componentFromStr(result[5], result[6])

        hex = '#' + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1)
      }
      return hex
    }
  }
}
