<template>
<div class="flex h-full w-full md:w-11/12 md:mx-auto 2xl:w-full 2xl:pl-10 px-4 overflow-hidden">
  <div class="flex h-full flex-col w-full">
    <div class="flex flex-wrap p-2">
      <legend-label
        v-for="(category, index) in categories"
        :key="index"
        :category="category"
        :color="colors[index]"
        customClass="mr-2 mb-2"
        @toggle="toggleSeries"
        @highlight="highlightSeries"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
      />
    </div>
    <div class="flex flex-grow w-full pb-6 flex-shrink">
      <template v-if="load">
        <highcharts v-if="averageChart" :ref="averageChart.ref" class="w-1/5 border-r-2 border-dotted border-secondary-100" :options="averageChart.options"></highcharts>
        <highcharts v-if="generalChart" :ref="generalChart.ref" class="w-full" :options="generalChart.options"></highcharts>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import LegendLabel from '@/components/LegendLabel'
import { deepClone } from '@/utils/general'

export default {
  name: 'AppFeatureViewAHighcharts',
  components: {
    LegendLabel
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    disableLegend: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: 'table'
    }
  },
  data: function () {
    return {
      localData: deepClone(this.data),
      legendHover: null,
      legendDisable: [],
      checkedLegend: [],
      load: false
    }
  },
  computed: {
    references: function () {
      return this.localData.map(item => item.ref)
    },
    averageChart: function () {
      return this.localData[0]
    },
    generalChart: function () {
      return this.localData[1]
    }
  },
  created: function () {
    this.$emit('changeActiveCategories', this.categories)
    this.loadChart()
  },
  methods: {
    toggleSeries: function (name) {
      if (this.legendDisable.includes(name)) {
        this.legendDisable = this.legendDisable.filter(item => item !== name)
      } else {
        this.legendDisable.push(name)
      }

      for (const ref of this.references) {
        const index = this.getChartIndexByName(name, ref)
        if (index >= 0) {
          const chart = this.$refs[ref].chart
          if (chart) {
            const series = chart.series
            if (series[index].visible) {
              series[index].hide()
            } else {
              series[index].show()
            }
          }
        }
      }
    },
    highlightSeries: function (value) {
      if (this.checkedLegend.includes(value)) {
        this.checkedLegend = this.checkedLegend.filter(item => item !== value)
      } else {
        this.checkedLegend.push(value)
      }
    },
    onMouseover: function (name) {
      this.legendHover = name
    },
    onMouseleave: function () {
      this.legendHover = null
    },
    getChartIndexByName: function (brandName, serieRef) {
      let index = -1
      const object = this.localData.find(item => item.ref === serieRef)

      if (object) {
        index = object.series.findIndex(serie => serie.name === brandName)
      }

      return index
    },
    addInactiveSeriesAll: function () {
      this.setStateSeriesAll('inactive')
    },
    removeInactiveSeriesAll: function () {
      this.setStateSeriesAll()
    },
    removeInactiveSerie: function (name) {
      this.setStateOfSerie(name, 'hover')
    },
    setStateSeriesAll: function (state = '') {
      for (const reference of this.references) {
        const chartEl = this.$refs[reference]
        if (chartEl && chartEl.chart) {
          const series = chartEl.chart.series
          if (series && series.length) {
            for (const serie of series) {
              serie.setState(state)
            }
          }
        }
      }
    },
    setStateOfSerie: function (name, state = '') {
      for (const ref of this.references) {
        const index = this.getChartIndexByName(name, ref)
        if (index >= 0) {
          const chartEl = this.$refs[ref]
          if (chartEl && chartEl.chart) {
            const chart = chartEl.chart
            const series = chart.series
            if (series) {
              if (index < series.length) {
                series[index].setState(state)
              }
            }
          }
        }
      }
    },
    setCheckedLegend: function () {
      if (this.checkedLegend.length) {
        this.addInactiveSeriesAll()
        for (const value of this.checkedLegend) {
          this.removeInactiveSerie(value)
        }
      } else {
        this.removeInactiveSeriesAll()
      }
    },
    serieMouseOut: function () {
      setTimeout(() => {
        this.legendHover = null
      }, 100)
    },
    serieMouseOver: function (event) {
      const name = event.target.name
      setTimeout(() => {
        this.legendHover = name
      }, 100)
    },
    setSeriesEvents: function (data) {
      for (const chart of data) {
        chart.options.plotOptions.series.events = {
          mouseOut: this.serieMouseOut.bind(this),
          mouseOver: this.serieMouseOver.bind(this)
        }
      }

      return data
    },
    loadChart: function () {
      this.localData = this.setSeriesEvents(this.localData)
      this.load = false
      setTimeout(() => {
        this.load = true
      }, 100)
    }
  },
  watch: {
    legendHover: function (value) {
      if (value !== null) {
        this.addInactiveSeriesAll()
        this.removeInactiveSerie(value)

        for (const item of this.checkedLegend) {
          this.removeInactiveSerie(item)
        }
      } else {
        this.setCheckedLegend()

        for (const item of this.checkedLegend) {
          this.removeInactiveSerie(item)
        }
      }
    },
    checkedLegend: function () {
      this.setCheckedLegend()
    },
    data: function () {
      this.localData = deepClone(this.data)
      this.loadChart()
    },
    legendDisable: function () {
      const legendDisable = this.legendDisable
      const categories = this.categories.filter(category => !legendDisable.includes(category))
      this.$emit('changeActiveCategories', categories)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  .chart {
    flex-grow: 1;
  }
  .legend {
    width: 300px;
    padding: 20px;
  }
}
.legend {
  .legend-item {
    display: flex;
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    .color {
      display: block;
      width: 1em;
      height: 1em;
      margin-right: .5em;
    }
    input {
      display: none;
    }
    &[data-disabled] {
      background: grey;
    }
  }
}
</style>
