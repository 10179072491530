<template>
  <div class="flex flex-col h-full flex-grow overflow-hidden">
    <nav class="flex items-center justify-center mb-4 flex-shrink-0 mt-4">
      <ul :class="'grid-cols-' + dimensions.length" class="grid bg-white shadow rounded-lg h-8 divide-x overflow-hidden mr-4">
        <li v-for="dimension in dimensions"
          :key="dimension.key"
          :class="{'bg-secondary text-white':isActive(dimension.key)}"
          class="flex justify-center uppercase text-xs items-center w-full px-6 h-full cursor-pointer"
          @click="setActiveTab(dimension.key)"
        >
          <h3 class="text-sm leading-tight font-bold">{{ dimension.name }}</h3>
        </li>
      </ul>
      <tab-nav :tabs="tabs" @tabActive="setView" />
    </nav>
    <template v-if="anyChartResult(data)">
      <template v-if="activeView === 'barchart'">
        <app-feature-view-a-highcharts ref="generalCharts" :data="dataViewA" :categories="brands" :colors="colors" @changeActiveCategories="changeActiveCategories" />
      </template>
      <template v-else>
        <app-feature-view-b-highcharts ref="treemapCharts" :data="dataViewB" :categories="brands" :colors="colors" @changeActiveCategories="changeActiveCategories" />
      </template>
    </template>
    <template v-else-if="touch">
      <base-not-found />
    </template>
  </div>
</template>

<script>
import AppFeatureViewAHighcharts from '@/components/charts/AppFeatureViewAHighcharts'
import AppFeatureViewBHighcharts from '@/components/charts/AppFeatureViewBHighcharts'
import moment from 'moment'
import { getFilter1, getFilter2, getFilter3, getFilter4, getFilter5 } from '@/services/features/getFeatures'
import { transformFeatures } from '@/utils/transformDataToHighcharts'
import { getColors } from '@/utils/colors'
import colorMixin from '@/mixins/colorMixin'
import csvMixin from '@/mixins/csvMixin'
import TabNav from '@/components/TabNavLocal'

export default {
  name: 'Features',
  components: {
    AppFeatureViewAHighcharts,
    AppFeatureViewBHighcharts,
    TabNav
  },
  mixins: [colorMixin, csvMixin],
  data: function () {
    return {
      touch: false,
      activeTab: 'filter1',
      tableView: true,
      filterPanel: false,
      tabs: ['barchart', 'blocks'],
      activeView: 'barchart',
      data: [],
      brands: [],
      colors: [],
      activeCategories: [],
      csvLinks: [
        { ref: 'general', title: this.$t('csv.generalFeatures'), subtitle: this.$t('csv.file') },
        { ref: 'average', title: this.$t('csv.averageFeatures'), subtitle: this.$t('csv.file') },
        { ref: 'brands', title: this.$t('csv.brandsFeatures'), subtitle: this.$t('csv.file') }
      ]
    }
  },
  computed: {
    locale: function () {
      return this.$store.getters['auth/locale']
    },
    dimensions: function () {
      const locale = this.locale
      return this.$store.getters['filters/names'](locale)
    },
    filterChangeCounter: function () {
      return this.$store.getters['filters/changeCounter']
    },
    selectedFilter1: function () {
      return this.$store.getters['filters/selectedDimensions'].filter1
    },
    selectedFilter2: function () {
      return this.$store.getters['filters/selectedDimensions'].filter2
    },
    selectedFilter3: function () {
      return this.$store.getters['filters/selectedDimensions'].filter3
    },
    selectedFilter4: function () {
      return this.$store.getters['filters/selectedDimensions'].filter4
    },
    selectedFilter5: function () {
      return this.$store.getters['filters/selectedDimensions'].filter5
    },
    groupId: function () {
      return this.$store.getters['filters/groupId']
    },
    categoryId: function () {
      return this.$store.getters['filters/categoryId']
    },
    siteId: function () {
      return this.$store.getters['filters/siteId']
    },
    startDate: function () {
      return this.$store.getters['filters/startDate']
    },
    endDate: function () {
      return this.$store.getters['filters/endDate']
    },
    minPrice: function () {
      return this.$store.getters['filters/minPrice']
    },
    maxPrice: function () {
      return this.$store.getters['filters/maxPrice']
    },
    currency: function () {
      return this.$store.getters['filters/currency']
    },
    brand: function () {
      return this.$store.getters['auth/user']?.nombreMarca
    },
    color: function () {
      return this.$store.getters['auth/user']?.color
    },
    csvChangeCounter: function () {
      return this.$store.getters['app/csvCounter']
    },
    csvRef: function () {
      return this.$store.getters['app/csvOptions'].ref
    },
    dataViewA: function () {
      return this.data.slice(0, 2)
    },
    dataViewB: function () {
      return this.data.slice(2)
    },
    averageChart: function () {
      if (this.data.length) {
        return this.data[0]
      }
      return null
    },
    generalChart: function () {
      if (this.data.length >= 2) {
        return this.data[1]
      }
      return null
    },
    treemapChart: function () {
      if (this.data.length >= 3) {
        return this.data[2]
      }
      return null
    },
    selectedHealthIndexFilter: function () {
      return this.$store.getters['filters/healthIndexFilter'].selected
    },
    actualCategories: function () {
      return this.activeCategories
    }
  },
  created: function () {
    this.$store.commit('app/setCsvLinks', this.csvLinks)
    this.downloadingData()
  },
  methods: {
    setActiveTab: function (val) {
      this.activeTab = val
    },
    isActive: function (val) {
      return this.activeTab === val
    },
    setView: function (val) {
      this.activeView = val
    },
    downloadingData: async function () {
      try {
        this.$store.commit('app/setLoading', true)
        await this.fetchFeatures()
      } catch (error) {
        this.doFeedback()
      } finally {
        this.touch = true
        this.$store.commit('app/setLoading', false)
      }
    },
    fetchFeatures: async function () {
      const groupId = this.groupId
      const categoryId = this.categoryId
      const siteId = this.siteId
      const startDate = moment(this.startDate).format('DD-MM-YYYY')
      const endDate = moment(this.endDate).format('DD-MM-YYYY')
      const body = {
        precioMin: this.minPrice,
        precioMax: this.maxPrice
      }

      if (this.selectedHealthIndexFilter && this.selectedHealthIndexFilter.length === 1) {
        body.healthIndex = this.selectedHealthIndexFilter[0].toString()
      }
      if (this.selectedFilter1 && this.selectedFilter1.length) {
        body.filtro1 = this.selectedFilter1
      }
      if (this.selectedFilter2 && this.selectedFilter2.length) {
        body.filtro2 = this.selectedFilter2
      }
      if (this.selectedFilter3 && this.selectedFilter3.length) {
        body.filtro3 = this.selectedFilter3
      }
      if (this.selectedFilter4 && this.selectedFilter4.length) {
        body.filtro4 = this.selectedFilter4
      }
      if (this.selectedFilter5 && this.selectedFilter5.length) {
        body.filtro5 = this.selectedFilter5
      }

      const data = await (async (groupId, categoryId, siteId, startDate, endDate, body) => {
        const activeTab = this.activeTab
        switch (activeTab) {
          case 'filter1':
            return getFilter1(groupId, categoryId, siteId, startDate, endDate, body)
          case 'filter2':
            return getFilter2(groupId, categoryId, siteId, startDate, endDate, body)
          case 'filter3':
            return getFilter3(groupId, categoryId, siteId, startDate, endDate, body)
          case 'filter4':
            return getFilter4(groupId, categoryId, siteId, startDate, endDate, body)

          default:
            return getFilter5(groupId, categoryId, siteId, startDate, endDate, body)
        }
      })(groupId, categoryId, siteId, startDate, endDate, body)

      this.$store.commit('filters/setHealthIndexOK', data.healthIndexOK)
      this.$store.commit('filters/setHealthIndexKO', data.healthIndexKO)
      this.$store.commit('filters/setFilters', {
        filter1: data.filtro1,
        filter2: data.filtro2,
        filter3: data.filtro3,
        filter4: data.filtro4,
        filter5: data.filtro5
      })

      let colors = []
      let brands = []
      let featuresData = []

      if (data.lista.length) {
        const transformData = transformFeatures(data.lista)
        featuresData = transformData.charts
        brands = transformData.brands

        colors = getColors(brands.length)

        featuresData = this.setChartsColors(featuresData, brands, colors)
      }

      this.colors = colors
      this.brands = brands
      this.data = featuresData

      this.$store.commit('filters/setResultsCounter', brands.length)
    },
    setChartsColors: function (charts, brands, colors) {
      for (const chart of charts) {
        if (chart.series[0].type !== 'treemap') {
          const seriesBrands = chart.series.map(serie => serie.name)
          const c = this.getColorsByBrands(seriesBrands, brands, colors)
          chart.options.colors = c
        } else {
          chart.series = chart.series[0].data.map(serie => {
            const brand = serie.id || serie.parent
            const color = this.getColorByBrand(brand, brands, colors)
            serie.color = color

            return serie
          })
        }
      }

      return charts
    },
    getColorsByBrands: function (seriesBrands, brands, colors) {
      return seriesBrands.map(brand => {
        const position = brands.findIndex(b => b === brand)
        return this.rgbToHex(colors[position])
      })
    },
    getColorByBrand: function (brand, brands, colors) {
      const position = brands.findIndex(b => b === brand)
      return colors[position]
    },
    changeActiveCategories: function (categories) {
      this.activeCategories = categories
    }
  },
  watch: {
    filterChangeCounter: function () {
      this.downloadingData()
    },
    activeTab: function () {
      this.downloadingData()
    },
    csvChangeCounter: function () {
      const ref = this.csvRef

      if (ref === 'general') {
        const data = this.getCSVFromColumnChart(this.generalChart.options)
        this.downloadCsv(data, 'features')
      } else if (ref === 'average') {
        const data = this.getCSVFromColumnChart(this.averageChart.options, 'percent', null)
        this.downloadCsv(data, 'features-average')
      } else if (ref === 'brands') {
        const data = this.getCSVFromTreemapChart(this.treemapChart.options, 'percent', null)
        this.downloadCsv(data, 'features-brands')
      }
    }
  }
}
</script>
