<template>
  <li
    :class="[active ? 'bg-primary text-white ':'cursor-pointer hover:text-primary']"
    class="w-9 h-7 flex items-center justify-center rounded-lg px-1"
    v-on="listeners"
  >
    <base-icon :name="icon" />
  </li>
</template>

<script>
export default {
  name: 'TabNavItem',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    listeners: function () {
      return {
        ...this.$listeners
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
