<template>
  <ul class="flex items-center bg-white h-8 shadow hover:shadow-md rounded-lg px-1" :disabled="disabled">
    <tab-nav-item
      v-for="(tab,i) in tabs"
      :key="i"
      :icon="tab"
      :active="current === tab"
      :id="i"
      @click="setActiveTab(tab)"
    />
  </ul>
</template>

<script>
import TabNavItem from '@/components/TabNavItem'
export default {
  name: 'tab-nav-local',
  props: {
    tabs: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      current: this.tabs[0]
    }
  },
  components: {
    TabNavItem
  },
  methods: {
    setActiveTab: function (value) {
      this.current = value
      this.$emit('tabActive', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-animated {
  a {
    position: relative;
    transition: all 0.3s;
    color: var(--secondary-500);
    z-index: 10;
    &.active {
      color: white;
    }
  }
  span {
    transition: all 0.3s;
    &.active {
      height: 100%;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
    }
  }
}
ul[disabled ] {
  position: relative;
  cursor: not-allowed;
  * {
    pointer-events: none;
  }
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .4);
  }
}
</style>
